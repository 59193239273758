<template>
    <div class="container" :class="{'print':print, 'not-print': !print, 'theme-activite':themeActivite}">
        <template v-if="res.content">
            <div class="resources resources-input" :style="resStyle" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"
                @click="handleEditInput" >
                <i class="iconfont icon-edit" v-show="editBtnShow" @click.stop="handlePanel"></i>
                <div class="input-val" style="white-space: pre-wrap;">
                    {{res.content}}
                </div>
            </div>
        </template>

        <template v-if="!res.content">
            <div class="resources resources-res" :style="resStyle" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"
                @click="checkDetail()">
                <i class="iconfont icon-edit" v-show="editBtnShow" @click.stop="handlePanel"></i>
                <div class="res-content">
                    <div class="res-title">{{res.name}}</div>
                    <div class="res-describe" v-if="resPreview">
                        <p class="left" :class='`left-${iconLen}`' >{{resPreview}}</p>
                        <div class="icons right" :sytle='`width: ${iconLen*22}px`' >
                            <img src="@/assets/images/res/video-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.VIDEO)">
                            <img src="@/assets/images/res/music-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.AUDIO)">
                            <img src="@/assets/images/res/ppt-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.PPT)">
                            <img src="@/assets/images/res/file-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.PACKAGE)">
                            <img src="@/assets/images/res/text-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.DOCUMENT)">
                            <img src="@/assets/images/res/excle-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.EXCLE)">
                            <img src="@/assets/images/res/image-w.png" v-if="resFiles.includes(UPLOAD_FILE_SUFFIX.IMAGE)">
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Core from '@/core';
import { deepCopy } from '@/core/utils';

const FULL_WEEK = ['周一', '周二', '周三', '周四', '周五','周六', '周日'];
const WEEK_MAP = {'周一':0, '周二':1, '周三':2, '周四':3, '周五':4,'周六':5, '周日':6};
const NORMAL_WEEK = ['周一', '周二', '周三', '周四', '周五'];

export default {
    props: {
        res: {
            type: Object
        },
        color: {
            type: String
        },
        index: {},
        size: {
            default: 1
        },
        editLock: {
            type: Boolean
        },
        showStyle: {
            default: 1
        },
        print: {
            type: Boolean,
            default: false,
        },
        themeActivite: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            editBtnShow: false,
            UPLOAD_FILE_SUFFIX: Core.Const.UPLOAD_FILE_SUFFIX,
            iconLen: 0,
            config: [],
        };
    },
    computed: {
        bgColor() {
            let bgColor = ''
            if(!this.color){
                const COLOR_BYSIZE_MAP = {
                    1: '#63AFF5',
                    2: '#FE8794',
                    3: '#FF85C0',
                    4: '#45E0B9',
                    5: '#FFC069'
                };
                bgColor = COLOR_BYSIZE_MAP[this.size]
            } else{
                bgColor = this.color;
            }
            return bgColor
        },
        resStyle() {
            if (this.showStyle == 0) {
                return { // 资源背景颜色
                    background: '#FFFFFF',
                    color: '#000000'
                };
            }
            return { // 资源背景颜色
                background: this.bgColor
            };
        },
        resFiles() { // 资源icon
            if (!this.res.files) {
                return '';
            }
            const flies = JSON.parse(this.res.files);
            let icons = [];
            flies.forEach((item) => {
                const fileType = Core.Utils.fileTypeFilter(item.name);
                if (icons.indexOf(fileType) === -1) {
                    icons.push(fileType);
                    this.iconLen = icons.length;
                }
            });
            return icons;
        },
        resPreview() {
            let text = '';
            const config = JSON.parse(this.res.config);
            config.forEach((item) => {
                if (item.title === '教学目标') {
                    item.content.map((i, index) => {
                        text = text + (index + 1) + '.' + i.value;
                    });
                }
            });
            return text;
        },
        resTooltip() { // 提示框文字
            if (!this.res.content) {
                const config = JSON.parse(this.res.config);
                return config
            }
            return 0
        },
    },
    methods: {
        checkDetail() {
            if (this.editLock) {
                this.$router.push(`/resources/resources-view?id=${this.res.id}`);
            }  else {
                let routeUrl = this.$router.resolve(`/resources/resources-view?id=${this.res.id}`)
                window.open(routeUrl .href, '_blank');
            }
        },
        handleEditInput() {
            this.$emit('handleEditInput', this.index, this.res.content);
        },
        handleMouseenter() { // 鼠标移入
            if (this.editLock) {
                return;
            }
            this.editBtnShow = true;
        },
        handleMouseleave() { // 鼠标移出
            this.editBtnShow = false;
        },
        handlePanel(e){ // 显示设置面板
            if (this.editPanel === true){
                this.editPanel = false;
            } else {
                e = e || window.event;
                let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
                let scrollY = document.documentElement.scrollTop || document.body.scrollTop;

                let toTop = e.pageY || (e.clientY+scrollY),
                    toBottom = (document.body.clientHeight || document.documentElement.clientHeight) - toTop;
                let toLeft = e.pageX || (e.clientX+scrollX),
                    toRight = (document.body.clientWidth || document.documentElement.clientWidth) - toLeft;

                // console.log("handlePanel -> toTop", toTop, 'toBottom', toBottom, 'toLeft', toLeft, 'toRight', toRight)

                if (toBottom < 80) {
                    toTop -= 330
                } else if (toBottom >= 80 && toBottom < 330) {
                    toTop -= 250
                }

                if (toRight > 250) {
                    this.editPanelPosition = {
                        top: toTop - 10 + 'px',
                        left: toLeft + 20 + 'px'
                    }
                } else {
                    this.editPanelPosition = {
                        top: toTop - 10 + 'px',
                        left: toLeft - 250 +'px'
                    }
                }
                this.editPanel = true
            }
            // console.log("handlePanel -> this.editPanelPosition", this.editPanelPosition)

            this.$emit('handlePanel', this.index,this.editPanel, this.editPanelPosition);
            this.$emit('handleColorPick', this.index, this.bgColor);
            this.$emit('handleDaySelect', this.index);
        },
    },
    created() {
        if (!this.res.content) {
            this.config = JSON.parse(this.res.config)
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    @include flex(row, center, center);
    .resources {
        cursor: pointer;
        width: calc(100% - 10px);
        box-sizing: border-box;
        background: rgba(99, 175, 245, 1);
        @include flex(column, center, center);
        border-radius: 6px;
        color: #fff;
        position: relative;
        &:hover{
            box-shadow:0 0 1px 0.5px rgba(57,61,64,.3);
        }
        .input-val {
            width: 90%;
        }
        .iconfont.icon-edit {
            font-size: 16px;
            cursor: pointer;
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
    .resources-res{
        padding: 14px 15px;
        @include flex(row, flex-start, center);
        .res-content{
            @include flex(column, center, center);
            width: 100%;
            text-align: left;
            .res-title {
                text-align: center;
                width: 100%;
                line-height: 20px;
                font-weight: 600;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
            .res-describe{
                @include flex(row, space-between, center);
                width: 100%;
                margin-top: 5px;
                .left{
                    &-0{width: 100%;}
                    &-1{width: calc(100% - 22px);}
                    &-2{width: calc(100% - 44px);}
                    &-3{width: calc(100% - 66px);}
                    &-4{width: calc(100% - 88px);}
                    &-5{width: calc(100% - 110px);}
                    &-6{width: calc(100% - 132px);}
                    line-height: 17px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }

                .right.icons {
                    // position: absolute;
                    // right: 4px;
                    // bottom: 2px;
                    @include flex(row, space-between, center);
                    img {
                        width: 15px;
                        height: 15px;
                        margin-left: 6px;
                        &:first-child{
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}
.container.not-print {
    height: 80px;
    .resources {
        height: 70px;
        .input-val {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
    .resources-res{
        .res-content{
            .res-describe{
                @include flex(row, space-between, center);
                width: 100%;
                margin-top: 5px;
                overflow: hidden;
            }
        }
    }
}

.container.print {
    height: 100%;
    align-items: flex-start;
    .resources {
        padding: 4px 9px;
        width: 100%;
        min-height: 60px;
        max-height: 86px;
        .res-content {
            .res-title {
                height: 20px;
                font-size: 14px;
                // font-family: STSongti-SC-Bold, STSongti-SC;
                font-family: $SongTi;
                font-weight: bold;
                color: #000000;
                line-height: 20px;
            }
            .res-describe {
                width: 100%;
                margin-top: 4px;
                overflow: hidden;
                p.left {
                    width: 100%;
                    font-size: 12px;
                    font-family: $SongTi;
                    font-weight: normal;
                    color: #000000;
                    line-height: 17px;
                    text-align: center;
                    -webkit-line-clamp: 3;
                }
                .right { display: none; }
            }
        }
        .input-val {
            width: 100%;
            font-size: 12px;
            font-family: $SongTi;
            font-weight: normal;
            color: #000000;
            line-height: 17px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }
    }
    .resources-input {
        padding: 6px 9px;
    }
}

.container.print.theme-activite {
    .resources {
        height: auto;
        max-height: 170px;
        .res-content {
            .res-describe {
                p.left {
                    -webkit-line-clamp: 7;
                }
            }
        }
        .input-val {
            -webkit-line-clamp: 8;
        }
    }
}
</style>
